.modal {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    min-height: 100vh;
    width: 100%;
    top: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modalContent {
    background-color: white;
    width: 91%;
    width: 380px;
    padding: 20px;
    border-radius: 8px;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
  }
  .modalContent div {
    word-wrap: break-word;
  }
  