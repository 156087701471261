/* $black: #000;
$white: #fff;
$pink: #fb409b;
$lightPink: #fceaf1;
$darkPink: #f7479e;
$gold: #e5c760;
$grey: #f8f8f8;
$darkGrey: #abb0b4; */
.spinBg{
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 100;
}
.spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }
  .spinDot {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 100px;
  }
  .spinDotSpin {
    transform: rotate(45deg);
    animation: Rotate 1.6s infinite linear;
  }
  @keyframes SpinMove {
    to {
      opacity: 1;
    }
  }
  @keyframes Rotate {
    to {
      transform: rotate(405deg);
    }
  }
  .spinDot i {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: block;
    position: absolute;
    opacity: 0.5;
    animation: SpinMove 1s infinite linear alternate;
    transform-origin: 50% 50%;
  }
  .spinDot i:nth-child(1) {
    left: calc(50% - 15px);
    top: 0;
    background: url('../../assets/images/loading/pick_jin_active.png') 100% center / contain;
  }
  .spinDot i:nth-child(2) {
    right: 0;
    top: 32px;
    animation-delay: 0.4s;
    background: url('../../assets/images/loading/pick_mu_active.png') 100% center / contain;
  }
  .spinDot i:nth-child(3) {
    right: 12px;
    bottom: 0;
    animation-delay: 0.8s;
    background: url('../../assets/images/loading/pick_shui_active.png') 100% center / contain;
  }
  .spinDot i:nth-child(4) {
    left: 12px;
    bottom: 0;
    animation-delay: 1.2s;
    background: url('../../assets/images/loading/pick_huo_active.png') 100% center / contain;
  }
  .spinDot i:nth-child(5) {
    left: 0;
    top: 32px;
    animation-delay: 1.6s;
    background: url('../../assets/images/loading/pick_tu_active.png') 100% center / contain;
  }
  