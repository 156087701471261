.id-top {
  background-color: #f7f8fa;
  /* min-height: 100vh; */
  padding: 16px 10px 0 10px;
  width: 100%;
  max-width: 380px;
  border-radius: 10px 10px 0 0;
}
.id-last {
  margin-bottom: 16px;
  font-size: 14px;
  padding: 15px 10px;
}
.id-last div:nth-child(2) {
  margin-left: 10px;
  color: #f7479e;
  font-weight: bold;
}
.id-inviteData,
.id-last {
  background-color: #ede5d4;
  border: 1px solid #b79e7d;
  border-radius: 8px;
  display: flex;
}
.id-inviteData {
  padding: 20px 18px;
  justify-content: space-between;
}
.id-inviteP {
  text-align: center;
  font-size: 14px;
}
.id-inviteP div:nth-child(1),
.id-last {
  color: #4d3921;
}
.id-inviteP div:nth-child(2) {
  color: #f7479e;
  font-weight: bold;
  margin-top: 12px;
}
.id-tab {
  display: flex;
  font-size: 14px;
  color: white;
  padding: 16px 0;
  position: relative;
}
.id-selected,
.id-noSelected {
  width: 38%;
  height: 48px;
  text-align: center;
  line-height: 48px;
  margin-right: 8px;
}
.id-selected {
  background: url('../../assets/images/jianghuOrder/jianghu_selected.png') no-repeat;
  background-size: 100% 100%;
}
.id-noSelected {
  background: url('../../assets/images/jianghuOrder/jianghu_default.png') no-repeat;
  background-size: 100% 100%;
}
.id-detail {
  font-size: 13px;
  background-color: #f7f8fa;
  padding: 0 10px 16px 10px;
  width: 100%;
  max-width: 380px;
  border-radius: 0 0 10px 10px;
  min-height: 600px;
}
.id-detailHeader {
  background-color: #493024;
  display: flex;
  color: #ead1b7;
  border-radius: 8px 8px 0 0;
  padding: 8px 16px;
}
.id-listItem {
  display: flex;
  background-color: white;
  padding: 16px 12px;
  font-weight: bold;
  color: #3f3f3f;
  border: 0.5px solid #f5f5f5;
}
.id-listItem div:nth-child(1),
.id-detailHeader div:nth-child(1) {
  width: 27%;
}
/* .id-detailHeader div {
  border: 1px solid red;
} */
.id-listItem div:nth-child(2),
.id-detailHeader div:nth-child(2) {
  width: 20%;
  text-align: center;
}
.id-listItem div:nth-child(3),
.id-detailHeader div:nth-child(3) {
  text-align: center;
  width: 30%;
}
.id-listItem div:nth-child(4),
.id-detailHeader div:nth-child(4) {
  width: 23%;
  text-align: right;
}
.id-listItem div:nth-child(3),
.id-listItem div:nth-child(4) {
  color: #f64da0;
}
.id-more {
  color: #f64da0;
  text-align: right;
  margin-top: 15px;
}
