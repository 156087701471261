.tab{
    width: 100%;
    max-width: 400px;
    font-size: 14px;
    color: white;
    display: flex;
    align-items: center;
    margin-top: 16px;
    position: relative;
}
.selected,.noSelected {
    width: 32%;
    height: 48px;
    text-align: center;
    line-height: 48px;
    margin-right: 8px;
  }
  .selected {
    background: url('../../assets/images/jianghuOrder/jianghu_selected.png') no-repeat 100% / contain;
  }
  .noSelected {
    background: url('../../assets/images/jianghuOrder/jianghu_default.png') no-repeat 100% / contain;
  }
  .filter{
    font-size: 12px;
    position: absolute;
    right: 17px;
  }
  .filter img{
    width: 13px;
    height: 13px;
    margin-right: 4px;
  }
  .detail {
    font-size: 13px;
    font-weight: bold;
    width: 100%;
    max-width: 400px;
    border-radius: 0 0 10px 10px;
    margin-top: 16px;
  }
  .detailHeader,.teamDetailHeader {
    background-color: #EDE5D4;
    display: flex;
    color: #65483C;
    border-radius: 8px 8px 0 0;
    padding: 9px 16px;
    position: relative;
  }
  .dropDown{
    width: 9px;
    height: 6px;
    margin-left: 2px;
  }
  .gameTypePopup{
    position: absolute;
    width: 110px;
    padding:12px 8px 40px 8px;
    border: 1px solid #485053;
  border-radius: 4px;
  font-size: 14px;
  color: white;
  background-color: #1b1a1e;
  top:41px;
  left: 80px;
  }
  .gameTypeItem{
    margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100% !important;
  }
  .gameTypeItem img{
    width: 15px;
    height: 10px;
  }
  .empty{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color:#808080;
    padding-top: 26px;
  }
  .empty img{
    width: 72px;
    height: 74px;
    margin-bottom:4px;
  }
  .listItem,.teamListItem {
    display: flex;
    align-items: center;
    padding: 10px 14px;
    color: #FFFFFF;
    margin-bottom: 1px;
    background-color: #2B2B2F;
  }
  /* .listItem div{
      border:1px solid red;
  } */
  .listItem div:nth-child(1),
  .detailHeader div:nth-child(1) {
    width: 27%;
  }
  .listItem div:nth-child(2),
  .detailHeader div:nth-child(2) {
    width: 20%;
    text-align: center;
  }
  .listItem div:nth-child(3),
  .detailHeader div:nth-child(3) {
    text-align: center;
    width: 35%;
  }
  .listItem div:nth-child(4),
  .detailHeader div:nth-child(4) {
    width: 18%;
    text-align: right;
  }
  .listItemToken{
      font-size: 10px;
      color:#7F7F82;
      text-align: center;
      width: 100% !important;
  }
  .more {
    color: #f64da0;
    text-align: right;
    margin-top: 15px;
  }
  .teamDetailHeader,.teamListItem{
    justify-content: space-between;
  }
  .teamListItem{
      padding:17px 14px;
  }
  .teamListItem span{
      color:#AB9C87;
      font-size: 10px;
      margin-left: 4px;
  }
  .filterModal {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    min-height: 100vh;
    width: 100%;
    max-width: 420px;
    top: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .filterModalCon{
    width: 100%;
    max-width: 420px;
      color:white;
      padding:30px 12px 16px 12px;
      background-color: #1C1C1E;
      border-radius: 8px 8px 0 0;
  }
  .filterModalHeader{
    padding-bottom: 8px;
    border-bottom: 1px solid #29292C;
  }
  .filterModalHeader,.filterModalYear{
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .filterModalHeader img{
      width: 50px;
      height: auto;
  }
  .filterModalYear{
      margin-top: 40px;
    margin-bottom: 27px;
  }
  .filterModalYear img{
    width: 22px;
    height: 22px;
  }
  .filterModalMonth{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .selectM,.selectedM{
    width: 32%;
    margin-bottom: 12px;
    border-radius: 4px;
    height: 36px;
    line-height: 36px;
    text-align: center;
  }
  .selectM{
    background-color: #2B2B2F;
  }
  .selectedM{
      color:#FF4EB4;
    background-image: url('../../assets/images/jianghuOrder/selectedMonth.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}