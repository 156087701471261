.ed-top {
  background-color: #f7f8fa;
  padding: 16px 10px 0 10px;
  width: 100%;
  max-width: 380px;
  border-radius: 10px 10px 0 0;
}
.ed-last {
  margin-bottom: 16px;
  font-size: 14px;
  color: #4d3921;
  display: flex;
  justify-content: space-between;
}
.ed-last div:nth-child(2) {
  margin-left: 10px;
  color: #f7479e;
  font-weight: bold;
}
.ed-energy,
.ed-last {
  background-color: #ede5d4;
  border: 1px solid #b79e7d;
  border-radius: 8px;
  padding: 15px 10px;
}
.ed-energyData {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.ed-energyTitle {
  display: flex;
  align-items: center;
}
.ed-energyTitle img {
  margin-right: 5px;
  width: 24px;
  height: auto;
}
.ed-energyCount {
  color: #f7479e;
  font-weight: bold;
}
.ed-end {
  font-size: 10px;
  color: #9d8f7a;
  margin-top: 5px;
}
.ed-detail {
  font-size: 13px;
  background-color: #f7f8fa;
  padding: 16px 10px;
  width: 100%;
  max-width: 380px;
  border-radius: 0 0 10px 10px;
  min-height: 600px;
}
.ed-detailHeader {
  background-color: #493024;
  display: flex;
  color: #ead1b7;
  border-radius: 8px 8px 0 0;
  padding: 8px 16px;
}
.ed-listItem {
  display: flex;
  background-color: white;
  padding: 16px 12px;
  font-weight: bold;
  color: #3f3f3f;
  border: 0.5px solid #f5f5f5;
}
.ed-listItem div:nth-child(1),
.ed-detailHeader div:nth-child(1) {
  width: 75%;
}
/* .ed-detailHeader div {
    border: 1px solid red;
  } */
.ed-listItem div:nth-child(2),
.ed-detailHeader div:nth-child(2) {
  width: 24%;
  text-align: right;
}

.ed-listItem div:nth-child(2) {
  color: #f64da0;
}
.ed-more {
  color: #f64da0;
  text-align: right;
  margin-top: 15px;
}

