.nameListMain {
  margin-top: 28px;
  height: 629px;
  background-image: linear-gradient(#707b81, #171f2a);
  border-radius: 8px;
  position: relative;
  color: white;
  width: 100%;
  max-width: 400px;
}
.mainContent {
  background-image: linear-gradient(#211c1e, #100b10);
  border-radius: 8px;
  height: 623px;
  margin: 3px;
  padding: 26px 9px;
}
.nameListImg {
  width: 96px;
  height: auto;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mainTitle {
  background-image: linear-gradient(#707b81, #171f2a);
  border-radius: 8px;
  height: 60px;
  font-size: 13px;
  padding: 3px;
}
.mainTitleCon {
  background-image: linear-gradient(#211c1e, #100b10);
  border-radius: 8px;
  height: 54px;
  padding-top: 12px;
  text-align: center;
}
.mainTitleCon div:nth-child(2) {
  font-size: 10px;
  color: #999999;
}
.ranking {
  padding: 8px;
}
.rankingTitle {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #707b81;
  height: 51px;
  font-size: 13px;
  align-items: center;
  padding: 0 8px;
  text-align: center;
}
.rankingItem {
  font-size: 14px;
  margin-top: 10px;
  height: 36px;
  background-color: rgba(134, 78, 57, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  text-align: center;
  font-weight: 500;
}
.rankingItem2 {
  background-color: rgba(211, 142, 22, 0.2);
}
.rankingItem span {
  display: block;
}
.rankingItem span:nth-child(1),
.rankingTitle span:nth-child(1) {
  text-align: left;
  width: 15%;
}
.rankingItem span:nth-child(2),
.rankingTitle span:nth-child(2) {
  width: 30%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rankingItem span:nth-child(3),
.rankingTitle span:nth-child(3) {
  width: 15%;
}
.rankingItem span:nth-child(4),
.rankingTitle span:nth-child(4) {
  width: 25%;
}
.rankingItem img {
  width: 32px;
  height: auto;
}
