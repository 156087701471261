/* $black: #000;
$white: #fff;
$pink: #fb409b;
$lightPink: #fceaf1;
$darkPink: #f7479e;
$gold: #e5c760;
$grey: #f8f8f8;
$darkGrey: #abb0b4; */

.spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
.spin-dot {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
}
.spin-dot-spin {
  transform: rotate(45deg);
  animation: Rotate 1.6s infinite linear;
}
@keyframes SpinMove {
  to {
    opacity: 1;
  }
}
@keyframes Rotate {
  to {
    transform: rotate(405deg);
  }
}
.spin-dot i {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: block;
  position: absolute;
  opacity: 0.5;
  animation: SpinMove 1s infinite linear alternate;
  transform-origin: 50% 50%;
}
.spin-dot i:nth-child(1) {
  left: calc(50% - 15px);
  top: 0;
  background: url('img/pick_jin_active.png') 100% center / contain;
}
.spin-dot i:nth-child(2) {
  right: 0;
  top: 32px;
  animation-delay: 0.4s;
  background: url('img/pick_mu_active.png') 100% center / contain;
}
.spin-dot i:nth-child(3) {
  right: 12px;
  bottom: 0;
  animation-delay: 0.8s;
  background: url('img/pick_shui_active.png') 100% center / contain;
}
.spin-dot i:nth-child(4) {
  left: 12px;
  bottom: 0;
  animation-delay: 1.2s;
  background: url('img/pick_huo_active.png') 100% center / contain;
}
.spin-dot i:nth-child(5) {
  left: 0;
  top: 32px;
  animation-delay: 1.6s;
  background: url('img/pick_tu_active.png') 100% center / contain;
}
