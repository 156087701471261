/* .jho-all {
  min-height: 100vh;
  background-color: #493024;
  position: relative;
  width: 100%;
  max-width: 380px;
} */
.backBlack {
  background-color: black;
  padding-top: 11px;
  width: 100%;
  max-width: 400px;
}
.jho-income {
  background: url('../../assets/images/jianghuOrder/jianghu_bg.png') no-repeat;
  background-size: 100% 100%;
  width: 91%;
  margin: auto;
  padding-top: 39px;
  padding-bottom: 10px;
  text-align: center;
}

.promoteIV {
  margin-bottom: 23px;
  font-size: 22px !important;
}
.distribute {
  background-color: #1c1412;
  width: 89%;
  border-radius: 8px;
  margin: auto;
  display: flex;
  padding: 10px 12px;
  justify-content: space-between;
}
.distributeI div:nth-child(1),
.promote {
  font-size: 13px;
  color: white;
  margin-bottom: 15px;
}
.distributeI div:nth-child(2),
.promoteIV {
  font-size: 19px;
  color: #f7479e;
  font-weight: bold;
}
.jho-main {
  position: relative;
  width: 100%;
  max-width: 400px;
  /* background-color: #493024; */
}
.jho-tab {
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: white;
  border: 1px solid #825840;
  border-radius: 8px;
  padding: 6px;
  margin-bottom: 16px;
  position: relative;
  background-color: #483024;
}
.jho-selected,.jho-noSelected {
  width: 32%;
  height: 48px;
  text-align: center;
  line-height: 48px;
}
.jho-selected {
  background: url('../../assets/images/jianghuOrder/jianghu_selected.png') no-repeat 100% / contain;
}
.jho-noSelected {
  background: url('../../assets/images/jianghuOrder/jianghu_default.png') no-repeat 100% / contain;
}

.jho-task {
  width: 100%;
  max-width: 400px;
  margin: auto;
  background-color: #c7bfae;
  border: 1px solid #906e4c;
  border-radius: 8px;
  padding: 8px;
}
.jho-superior{
  font-size: 13px;
  padding: 6px 8px;
  margin-bottom: 8px;
  color: #4d3921;
  display: flex;
  justify-content: space-between;
}
.jho-superior div:nth-child(2){
  color:#F7479E;
}
.payableIncome {
  color: #4d3921;
  font-size: 12px;
  padding: 15px 8px;
  margin-bottom: 8px;
}
.payableIncomeTitle,
.payableIncomeCon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.payableIncomeTitle {
  margin-bottom: 8px;
}
.payableIncomeTitleSpan{
  color:#AB9C87;
  font-size: 10px;
  margin-left: 4px;
}
.payableIncomeCon {
  font-weight: bold;
  padding: 0 8px;
  margin-top: 8px;
}
.payableIncomeCon div:nth-child(2) {
  background-color: #65483c;
  padding: 2px 10px;
  color: #efb549;
  font-size: 13px;
  border-radius: 4px;
}
.receiveAll {
  background: url('../../assets/images/jianghuOrder/jianghu_receiveAll.png') no-repeat 100% / contain;
  width: 97px;
  height: 25px;
  line-height: 24px;
  color: white;
  font-size: 12px;
  text-align: center;
  margin-bottom: 9px;
  margin-left: auto;
}
.taskItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 8px;
  margin-bottom: 8px;
}

.taskItem,
.payableIncome,
.jho-inviteData,
.jho-quarterIncome,
.jho-energyDetailsItem,
.jho-superior
{
  background-color: #ede5d4;
  border: 1px solid #b79e7d;
  border-radius: 8px;
}

.taskDetail {
  width: 67%;
}
.taskDetail div:nth-child(1) {
  font-size: 13px;
  color: #4d3921;
  font-weight: bold;
}
.taskDetail div:nth-child(2) {
  font-size: 12px;
  color: #f7479e;
  margin-top: 8px;
}
.jho-receive,
.jho-receiveGrey {
  width: 70px;
  height: 39px;
  line-height: 39px;
  text-align: center;
  font-size: 13px;
}
.jho-receive {
  background: url('../../assets/images/jianghuOrder/jianghu_receive.png') no-repeat 100% / contain;
  color: white;
}
.jho-receiveGrey {
  background: url('../../assets/images/jianghuOrder/jianghu_received.png') no-repeat 100% / contain;
  color: #767779;
}

.taskProgress {
  /* width: 93px; */
  padding-right: 7px;
}
.taskProgress div {
  font-size: 12px;
  color: #4d3921;
  text-align: center;
}
.progressData {
  margin-left: 2px;
  color: #f7479e;
}
.jho-progress,.jho-progressCurrent {
  height: 8px;
  border-radius: 4px;
}
.jho-progress{
  position: relative;
  width: 80px;
  background-color: #65483C;
  margin-top: 6px;
}
.jho-progressCurrent{
  position: absolute;
  background-color: #EFB549;
}

.jho-blank2 {
  height: 34px;
}
.jho-invite {
  width: 100%;
  margin: auto;
  position: relative;
}
.jho-myInvite {
  position: absolute;
  width: 102px;
  height: 36px;
  padding: 4px;
  top: -20px;
  font-size: 15px;
  background-color: #745230;
}
.jho-myInvite div {
  background-color: #f7df7c;
  border: 3px solid #efa042;
  text-align: center;
  padding: 3px 0;
}
.jho-inviteDetail {
  background-color: #745230;
  padding: 8px 8px 16px 8px;
  border-radius: 0 8px 8px 8px;
}
.jho-inviteData {
  display: flex;
  justify-content: space-between;
  padding: 20px 18px;
}
.jho-inviteP {
  text-align: center;
  font-size: 14px;
}
.jho-inviteP div:nth-child(1) {
  color: #4d3921;
}
.jho-inviteP div:nth-child(2) {
  color: #f7479e;
  font-weight: bold;
  margin-top: 12px;
}
.jho-identity {
  background: url('../../assets/images/jianghuOrder/identitybg.png') no-repeat;
  background-size: 100% 100%;
  height: 169px;
  padding: 8px 8px 16px 8px;
  color: white;
  font-size: 11px;
}
.jho-identityExpl {
  padding: 0 25px;
  text-align: center;
}
.jho-identityDetail {
  display: flex;
  justify-content: space-between;
  padding: 0 43px;
  margin-top: 15px;
}

.jho-identityDetail div:nth-child(1) {
  background: url('../../assets/images/jianghuOrder/adventurerbg.png') no-repeat;
  background-size: 100% 100%;
}
.jho-identityDetail div:nth-child(2) {
  background: url('../../assets/images/jianghuOrder/explorerbg.png') no-repeat;
  background-size: 100% 100%;
}
.jho-identityDetail div {
  width: 93px;
  height: 96px;
  text-align: center;
  padding-top: 76px;
}
.jho-quarterIncome {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  margin-top: 8px;
}
.jho-quarterTitle {
  color: #4d3921;
  font-size: 12px;
}
.jho-seasonEnd {
  color: #9d8f7a;
  font-size: 10px;
  margin-top: 5px;
}
.jho-myEnergy {
  color: #4d3921;
  font-size: 13px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.jho-energyProgress {
  position: relative;
  margin-left: 8px;
}
.jho-energyProgress img {
  position: absolute;
  width: 24px;
  height: auto;
  z-index: 2;
  left: -4px;
  top: 1px;
}
.jho-energy {
  position: absolute;
  left: 25px;
  top: 1px;
  color: white;
  z-index: 2;
}
.jho-currentEnergy,
.jho-totalEnergy {
  height: 20px;
  border-radius: 10px;
}
.jho-currentEnergy {
  position: absolute;
  background-image: linear-gradient(to right, #fc8ad3, #f752a7);
  color: white;
  text-align: center;
  /* padding-right: 10px; */
  line-height: 20px;
  max-width: 93px;
}
.jho-totalEnergy {
  width: 93px;
  background-color: #c7bfae;
}
.jho-myIdentity img {
  width: 40px;
  height: auto;
}
.jho-myIdentity img:nth-child(1) {
  margin-right: 4px;
}
.jho-energyDetails {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #4d3921;
}
.jho-energyDetailsItem {
  margin-top: 8px;
  padding: 16px 1px;
  width: 49%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.jho-energyDetailsItem div:nth-child(1) {
  text-align: center;
}
.jho-energyDetailsItem div:nth-child(2) {
  margin-top: 8px;
  margin-bottom: 16px;
}
.jho-energyDetailCount {
  color: #f7479e;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}
.jho-blank3 {
  height: 100px;
}
.jho-inviteFriend {
  width: 92%;
  max-width: 400px;
  height: 75px;
  background-color: #000000;
  position: fixed;
  bottom: 83px;
  z-index: 3;
}
.jho-inviteFriend div {
  background-image: linear-gradient(#fc7dcd, #f7479e);
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: bold;
  margin: auto;
  border-radius: 4px;
}
.receiveModal {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  min-height: 100vh;
  width: 100%;
  max-width: 420px;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
.receiveContent {
  background-color: white;
  width: 91%;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  font-size: 14px;
}
.receiveContent div {
  word-wrap: break-word;
}
.receiveContent div:nth-child(1) {
  font-size: 15px;
  font-weight: bold;
}
.receiveContent div:nth-child(2) {
  margin-top: 40px;
  margin-bottom: 45px;
}
.receiveContent div:nth-child(3) {
  color: #f653a2;
}
