
.lockTotal {
  color: white;
  width: 100%;
  max-width: 400px;
  height: 84px;
  margin: auto;
  background: url('img/back.png') no-repeat;
  background-size: 100% 100%;
  padding: 16px;
  font-size: 14px;
  font-weight: bold;
}
.lockTotal div:nth-child(2) {
  color: #f7479e;
  font-size: 20px;
  margin-top: 6px;
}
.empty {
  height: 18px;
}
.participate {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  max-width: 400px;
}
.participate img {
  width: 14px;
  height: 14px;
  margin-right: 8px;
}
.participate div {
  color: white;
  font-size: 12px;
}
.list {
  width: 100%;
  max-width: 400px;
}
.listItem {
  color: white;
  font-size: 12px;
  margin: auto;
  padding: 16px;
  background-color: #1b1a1e;
  border-radius: 8px;
  position: relative;
  margin-bottom: 12px;
}
.listItemF{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
}
.listItemFLogo{
  display: flex;
  align-items: center;
  width: 55%;
}
.listItemFLogo img{
  width: 24px;
  height: 24px;
}
.listItemFEarn{
  width: 40%;
  text-align: right;
}
.listDataFR {
  color: #b0b0b0;
}
.listDataSR {
  margin-top: 8px;
  color:white;
  font-weight: 480;
}
.listAPR {
  display: flex;
  align-items: center;
  color:white;
}
.listAPR div:nth-child(2) {
  background-color: #f95cb1;
  color: black;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  margin-left: 4px;
  text-align: center;
  font-size: 10px;
  margin-top: 8px;
}
.explanation {
  position: absolute;
  border: 0.5px solid #646565;
  background-color: #1b1a1e;
  padding: 15px 8px;
  border-radius: 4px;
  top: 94px;
  right: 0;
  font-size: 12px;
  z-index: 2;
}
.exAPR span:nth-child(1) {
  margin-right: 14px;
}
.exAPR span:nth-child(2),
.exImg span:nth-child(3) {
  color: #f95cb1;
  font-size: 11px;
}
.exImg {
  margin-top: 4px;
  margin-bottom: 16px;
}
.exImg img {
  width: 6px;
  height: 15px;
  margin-right: 8px;
}
.exImg span:nth-child(2) {
  margin-right: 52px;
  color: #b0b0b0;
  font-size: 10px;
}
.main {
  width: 92%;
  max-width: 357px;
  margin: auto;
  color: white;
  font-size: 14px;
}
.partAura {
  background: url('img/back2.png') no-repeat;
  background-size: 100% 100%;
}
.partLP {
  border: 1px solid #707b81;
}
.partAura,
.partLP {
  height: 138px;
  border-radius: 8px;
  padding: 16px;
}
.extract {
  display: flex;
  justify-content: space-between;
}
.extract div,
.extractLP {
  background-image: linear-gradient(#fc7dcd, #f7479e);
  padding: 0 13px;
  height: 26px;
  border-radius: 4px;
  text-align: center;
  line-height: 26px;
}
.extract img {
  width: 42px;
  height: 42px;
}
.income {
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 4px;
}
.income span {
  color: #707b81;
  font-size: 10px;
  margin-left: 10px;
}
.incomeData {
  font-size: 22px;
  font-weight: bold;
  color: #f7479e;
}
.all {
  background-color: #1b1a1e;
  border-radius: 4px;
  height: 48px;
  line-height: 30px;
  margin-top: 32px;
  display: flex;
  padding: 9px;
}
.all div:nth-child(1) {
  border-right: 1px solid #646565;
  color: #999999;
}
.all div {
  width: 50%;
  text-align: center;
}
.lock {
  background-image: url('img/back3.png');
  /* width: 92%; */
  height: 385px;
  background-size: 100% 100%;
  padding: 16px 12px;
}
.lockTitle {
  color: #e5cf80;
  font-size: 14px;
  font-weight: bold;
}
.lockLP {
  display: flex;
  border: 1px solid #707b81;
  border-radius: 4px;
  padding: 16px 8px;
  margin-top: 16px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.lockLPData {
  font-size: 12px;
}
.lockLPData div:nth-child(2) {
  font-size: 13px;
  font-weight: bold;
  color: #f7479e;
  margin-top: 8px;
  text-align: center;
}
.lockRegular {
  margin-top: 16px;
}
.lockRegularItem {
  border: 1px solid #707b81;
  border-radius: 4px;
  padding: 9px 3px 9px 8px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}
.lockRegularItemLeft,
.lockRegularItemRight {
  display: flex;
  width: 41%;
  align-items: center;
}
.lockRegularItemRight {
  width: 57%;
}

.lockRegularItemLeft div:nth-child(2) {
  color: #e5cf80;
  font-size: 11px;
  margin-left: 10px;
}
.lockRegularItemRight div:nth-child(2) {
  color: #f7479e;
  font-size: 15px;
  font-weight: bold;
  margin-left: 8px;
}
.modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  min-height: 100vh;
  width: 100%;
  max-width: 420px;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalContent,
.modalContentDao {
  background-color: white;
  width: 91%;
  padding: 20px;
  border-radius: 8px;
  font-size: 14px;
}
.modalContent {
  text-align: center;
}
.modalContent div {
  word-wrap: break-word;
}
.modalContent div:nth-child(1) {
  font-size: 15px;
  font-weight: bold;
}
.modalContent div:nth-child(2) {
  margin-top: 40px;
  margin-bottom: 45px;
}
.modalContent div:nth-child(3) {
  color: #f653a2;
}
.spin-bg {
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 100;
}
.ant-spin {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ant-spin-lg .ant-spin-dot {
  font-size: 50px;
}
.modalContentDao {
  position: relative;
}
.close {
  position: absolute;
  width: 22px;
  height: auto;
  top: 16px;
  right: 16px;
}
.modalContentDaoTitle,
.modalAddCount div:nth-child(1) {
  font-size: 15px;
  font-weight: bold;
}
.modalContentDaoTitle2 {
  font-size: 15px;
  font-weight: 500;
  margin-top: 20px;
}
.modalLockWay {
  display: flex;
  flex-wrap: wrap;
  margin-top: 18px;
}
.modalLockWay div {
  width: 21%;
  height: 36px;
  border-radius: 4px;
  text-align: center;
  line-height: 36px;
  margin-bottom: 8px;
}
.modalLockWay div:not(:nth-child(4n)) {
  margin-right: calc(16% / 3);
}
.modalLockAPY {
  margin-top: 8px;
}
.modalLockAPY span,
.modalAddCount span,
.modalEnergy span {
  color: #f7479e;
  font-size: 16px;
  font-weight: bold;
}
.modalEnergy{
  color: #f7479e;
  margin-top: 8px;
}
.modalAddCount {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
}
.modalInput {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
  margin-top: 18px;
  height: 40px;
  box-sizing: border-box;
  align-items: center;
}
.input {
  height: 36px;
  width: 80%;
  text-align: right;
  border: none;
  font-size: 14px;
}
.input:focus {
  outline: none;
}
.modalInput div {
  color: white;
  background-image: linear-gradient(#fc7dcd, #f7479e);
  border-radius: 4px;
  padding: 2px 8px;
  margin: 5px 0;
}
.modalPledge {
  margin-top: 24px;
  color: white;
  background-image: linear-gradient(#fc7dcd, #f7479e);
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  height: 48px;
  line-height: 48px;
  text-align: center;
}
@media only screen and (max-width: 510px) {
  .goNameList {
    top: 16px;
    right: 60px;
  }
}
