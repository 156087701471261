.goPool {
  width: 120px;
  height: 22px;
  display: flex;
  align-items: center;
  color: white;
  font-size: 12px;
  background-image: linear-gradient(to right, #49b9ab, #337beb);
  border-radius: 11px;
  justify-content: center;
  position: absolute;
  top: 19px;
  right: 63px;
}
.goPool img {
  width: 10px;
  height: 10px;
  margin-left: 4px;
}
.modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  min-height: 100vh;
  width: 100%;
  max-width: 420px;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalContent {
  background-color: white;
  width: 91%;
  padding: 20px;
  border-radius: 8px;
  font-size: 14px;
  text-align: center;
}
.modalContent div {
  word-wrap: break-word;
}
.modalContent div:nth-child(1) {
  font-size: 15px;
  font-weight: bold;
}

